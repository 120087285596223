<template>
  <div class="container topCon">
<!--    <div class="ztTop"><span class="ztTitle">结算账户</span></div>-->
    <el-page-header style="margin-bottom: 20px" @back="goBack" content="结算账户">
    </el-page-header>
    <div class="wrapContent">
      <div class="data-db">
        <el-form ref="form" :model="form" label-width="132px" :rules="formRules" size="medium">
          <el-form-item label="账户类型" prop="bankAccountType">
            <el-select v-model="form.bankAccountType" placeholder="请选择">
              <el-option
                  v-for="item in bankOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开户名称" prop="accountName">
            <el-input v-model="form.accountName"></el-input>
            <div class="el-form-info">
              <!--              <p>1、选择“经营者个人银行卡”时，开户名称必须与“经营者证件姓名”一致;</p>-->
              <p>选择“对公银行账户”时，开户名称必须与营业执照上的“商户名称”一致;</p>
            </div>
          </el-form-item>
          <el-form-item label="开户行" prop="accountBank">
            <el-select v-model="form.accountBank" placeholder="请选择" class="province" @change="chooseBank">
              <el-option
                  v-for="item in bankList"
                  :key="item.bank_alias_code"
                  :label="item.bank_alias"
                  :value="item.bank_alias">
              </el-option>
            </el-select>
            <el-select v-model="form.bankAliasCode" placeholder="请选择" class="province" v-if="form.accountBank =='其他银行'" @change="chooseBranchBank"  v-el-select-loadmore="loadmore">
              <el-option
                  v-for="item in otherBankList"
                  :key="item.bank_alias_code"
                  :label="item.bank_alias"
                  :value="item.bank_alias_code">
              </el-option>
            </el-select>

          </el-form-item>
          <el-form-item label="开户支行" prop="provinceCode">
            <el-select v-model="form.provinceCode" placeholder="省份" @change="chooseProvince" class="province">
              <el-option
                  v-for="item in provinceList"
                  :key="item.province_code"
                  :label="item.province_name"
                  :value="item.province_code">
              </el-option>
            </el-select>
            <el-select v-model="form.cityCode" placeholder="城市" class="province" @change="chooseCity">
              <el-option
                  v-for="item in cityList"
                  :key="item.city_code"
                  :label="item.city_name"
                  :value="item.city_code">
              </el-option>
            </el-select>
            <el-select v-model="form.bankBranchId" placeholder="支行" class="province branchBank">
              <el-option
                  v-for="item in branchBankList"
                  :key="item.bank_branch_id"
                  :label="item.bank_branch_name"
                  :value="item.bank_branch_id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="银行账号" prop="accountNumber">
            <el-input v-model="form.accountNumber"></el-input>
            <span style="margin-left: 10px"><a class="gapl" target="_blank" href="http://kf.qq.com/faq/140225MveaUz150819mYFjuE.html">常用银行账号位数参考</a></span>
          </el-form-item>
        </el-form>
        <div class="bottomBtn">
          <el-button class="btn-defalut" @click="backToMerchantApply">返回</el-button>
          <el-button class="btn-defalut" v-if="isWrite == true && isSee == 'false'" @click="saveToDraft">保存草稿箱</el-button>
          <el-button class="success btn-defalut" @click="toNext" v-if="isWrite == true && isSee == 'false'">下一步</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  addBankAccountInfo,
  getProvince,
  getCity,
  getBank,
  getBranchBank,
  addSettlementInfo, getSettlementInfo, getBankAccountInfo
} from "../../../api/wxMerchant";
import {baseImgUrl} from "../../../utils/constant";

export default {
  name: "bankAccountInfo",
  components: {},
  directives: {
    'el-select-loadmore': {
      bind(el, binding) {
        // 获取element-ui定义好的scroll盒子
        const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      }
    }
  },
  data() {
    let token = localStorage.getItem("token")
    let info = JSON.parse(localStorage.getItem('info'));
    return {
      province:{},
      branchBankList:[],
      bankList:[],
      otherBankList:[],
      provinceList:[],
      cityList:[],
      dialogImageUrl: '',
      bankOptions:[],
      indoorPicFileList:[],
      subjectType:'',
      dialogVisible: false,
      form:{
        cardTime:'定期',
        bankAccountType:'BANK_ACCOUNT_TYPE_CORPORATE',
        bankBranchId:'',
        cityCode:''
      },
      isWrite:false,
      isSee:false,
      contactIdDocCopy:'',
      contactIdDocCopyBack:'',
      businessAuthorizationLetter:'',
      merchantId:'',
      uploadUrl:this.$store.state.uploadingUrl,
      ossUrl: this.$store.state.ossUrl,
      loading:false,
      links:'',
      otherBankLinks:'',
      offset:0,
      limit:50,
      isStop:false,
      upParams: {
        token: token
      },
      formRules: {
        bankAccountType:[
          { required: true, message: '请选择账户类型', trigger: 'change' },
        ],
        accountName:[
          { required: true, message: '请填写开户名称', trigger: 'blur' },
        ],
        contactIdNumber:[
          {  required: true, message: '请填写证件号码', trigger: 'change' }
        ],
        accountBank:[
          {  required: true, message: '请选择开户行', trigger: 'change' }
        ],
        bankAliasCode:[
          {  required: true, message: '请选择开户行', trigger: 'change' }
        ],
        provinceCode:[
          {  required: true, message: '请选择开户支行信息', trigger: 'change' }
        ],
        cityCode:[
          {  required: true, message: '请选择开户支行信息', trigger: 'change' }
        ],
        bankBranchId:[
          {  required: true, message: '请选择开户支行信息', trigger: 'change' }
        ],
        accountNumber:[
          {  required: true, message: '请填写银行账户信息', trigger: 'blur' }
        ]

      }
    };

  },
  computed: {},
  filters:{

  },
  watch: {},
  mounted() {

    if (this.subjectType == 'SUBJECT_TYPE_INDIVIDUAL'){
      this.bankOptions = [
        {
          value: 'BANK_ACCOUNT_TYPE_CORPORATE',
          label: '对公银行账户'
        }, {
          value: 'BANK_ACCOUNT_TYPE_PERSONAL',
          label: '经营者个人银行卡'
        }
      ]
    }else {
      this.bankOptions = [
        {
          value: 'BANK_ACCOUNT_TYPE_CORPORATE',
          label: '对公银行账户'
        }
      ]
    }
  },
  created() {
    this.merchantId = this.$route.query.merchantId
    this.subjectType = this.$route.query.subjectType
    if (this.$router.history.current.query.isSourceAdmin) {
      localStorage.setItem('isSourceAdmin', this.$router.history.current.query.isSourceAdmin);
    }
    this.isWrite = this.$route.query.isWrite
    this.isSee = this.$route.query.isSee
    if (this.isWrite=='false'){
      if (this.isSee == 'false'){
        this.$message({
          message: '请先填写上一模块信息',
          type: 'warning',
          duration:2000
        });
      }
    }else {
      this.isWrite = true
    }
    this.getProvince()
    this.getBank({offset:0,limit:18})
    this.getOtherBank({offset:18,limit:200})
    this.getBankAccountInfo()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    getBankAccountInfo(){
      getBankAccountInfo({merchantId:this.merchantId}).then(response=>{
        if (response.code == 200 && response.data){
          this.form = response.data
          this.form.provinceCode = Number(this.form.provinceCode)
          this.form.cityCode = Number(this.form.cityCode)
          getCity({provinceCode:this.form.provinceCode}).then(response=>{
            if (response.code == 200){
              this.cityList = response.data
            }
          })
          let params={
            bankAliasCode:this.form.bankAliasCode,
            cityCode:this.form.cityCode,
            offset:this.offset,
            limit:this.limit
          }
          this.getBranchBank(params)
        }
      })
    },
    loadmore() {
      if (this.otherBankLinks.next){
        let strings = this.otherBankLinks.next.split("?");
        let string = strings[1];
        let strings1 = string.split("&");
        let element = strings1[0];
        let limitStr = strings1[1];
        let offset = element.split("=")[1]
         let limit = limitStr.split("=")[1]
        this.getOtherBank({offset:offset,limit:limit})

      }

    },
    chooseCity(){
      this.form.bankBranchId = ''
      this.branchBankList = []
      this.cityList.forEach(item=>{
        if (item.city_code == this.form.cityCode){
          this.form.cityName= item.city_name
        }
      })
      if (this.form.accountBank && this.form.provinceCode && this.form.cityCode){
        let params={
          bankAliasCode:'',
          cityCode:this.form.cityCode,
          offset:this.offset,
          limit:this.limit
        }
        if ( this.form.accountBank == '其他银行' ){
          params.bankAliasCode = this.form.bankAliasCode
        }else{
          this.bankList.forEach(item=>{
            if (item.bank_alias == this.form.accountBank){
              params.bankAliasCode = item.bank_alias_code
              this.form.bankAliasCode = item.bank_alias_code
            }
          })
        }
        this.getBranchBank(params)
      }
    },
    getBranchBank(params){
      getBranchBank(params).then(response=>{
        if (response.code == 200 && response.data){
          this.branchBankList = [...this.branchBankList, ...response.data.data]
          this.links = response.data.links
          if (this.links.next){
            let number = this.links.next.indexOf("offset=");
            let strings = this.links.next.split("?");
            let string = strings[1];
            let strings1 = string.split("&");
            let element = strings1[0];
            let limit = strings1[1];
            params.offset = element.split("=")[1]
            params.limit = limit.split("=")[1]
            this.getBranchBank(params)
          }
        }
      })
    },
    chooseBranchBank(){
      this.chooseCity()
    },
    chooseBank(){
      this.branchBankList = []
      this.form.bankBranchId = ''
      if (this.form.accountBank == '其他银行'){
        this.getOtherBank({offset:18,limit:50})
      }
      this.chooseCity()
    },
    getOtherBank(params){
      getBank(params).then(response=>{
        if (response.code == 200){
          this.otherBankList = [...this.otherBankList, ...response.data.data]
          this.otherBankLinks = response.data.links
          if (this.form.bankAliasCode){
            response.data.data.forEach(item=>{
              if (item.bank_alias_code == this.form.bankAliasCode){
                this.isStop = true;
                  return;
              }
            })
            if (response.data.links && !this.isStop){
              let number = response.data.links.next.indexOf("offset=");
              let strings = response.data.links.next.split("?");
              let string = strings[1];
              let strings1 = string.split("&");
              let element = strings1[0];
              let limit = strings1[1];
              params.offset = element.split("=")[1]
              params.limit = limit.split("=")[1]
              this.getOtherBank(params)
            }
          }

        }
      })
    },
    getBank(params){
      getBank(params).then(response=>{
        this.bankList = response.data.data
        let obj = {
          bank_alias:'其他银行',
          bank_alias_code:0
        }
        this.bankList.push(obj)
      })
    },
    getProvince(){
      getProvince().then(response=>{
        this.provinceList = response.data
      })
    },
    chooseProvince(){
      this.cityList = []
      this.form.cityCode = ''
      this.form.bankBranchId = ''
      this.provinceList.forEach(item=>{
        if (item.province_code == this.form.provinceCode){
          this.form.provinceName= item.province_name
        }
      })
      getCity({provinceCode:this.form.provinceCode}).then(response=>{
        if (response.code == 200){
          this.cityList = response.data
        }
      })
    },
    handleIdContactIdDocCopySuccess(res,file){
      this.form.contactIdDocCopy = res.data
      this.contactIdDocCopy = this.ossUrl + this.form.contactIdDocCopy
    },
    handleIdCopyBackSuccess(res,file){
      this.form.contactIdDocCopyBack = res.data
      this.contactIdDocCopyBack = this.ossUrl + this.form.contactIdDocCopyBack
    },
    handleIdBusinessAuthorizationLetterSuccess(res,file){
      this.form.businessAuthorizationLetter = res.data
      this.businessAuthorizationLetter = this.ossUrl + this.form.businessAuthorizationLetter
    },
    toNext(){
      //跳转下个页面且将数据进行保存
      this.$refs.form.validate(async (valid) => {
        if (valid) {
         this.form.merchantId = this.merchantId
          this.form.isSubmit = true
          addBankAccountInfo(this.form).then(res => {
            if (res.code === 200) {
              this.$router.push({path:'/contactInfo',query:{'merchantId':this.merchantId,isWrite:true,isSee:this.isSee}})
              this.$message({
                message: '操作成功',
                type: 'success'
              });
            }
          })
        }
      })
    },
    backToMerchantApply(){
      this.$message.closeAll()
      let than = this
      setTimeout(function () {
        than.$router.push({path:'/finance/merchantApplyInfo',query:{merchantId:than.merchantId,isSee:than.isSee}})
      },0)
    },
    saveToDraft(){
      this.form.merchantId = this.merchantId
      addBankAccountInfo(this.form).then(res => {
        if (res.code === 200) {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
        }
      })
    },
  }
};
</script>

<style scoped>
.ztTitle{
  font-size: 18px;
}
.topCon {
  position: relative;

}
.ztTop{
  margin-top: 20px;
  margin-bottom: 20px;
}
.wrapContent{
  padding: 32px 24px;
  margin-bottom: 24px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 4px -1px rgb(0 0 0 / 8%);
}
.data-hd{
  margin-bottom: 30px;
  height: 14px;
  line-height: 1;
  padding-left: 10px;
  border-left: 3px solid #00c250;
}
.desc_info{
  font-size: 12px;
  color: #999;
  margin-left: 15px;
}
.descWord{
  color: #999;
  margin-top: 10px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 106px;
  line-height: 106px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
/deep/.el-upload--text {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 193px;
  height: 109px;
  position: relative;
  overflow: hidden;
}
.avatar-uploader-icon[data-v-e66b3640] {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 106px;
  line-height: 106px;
  text-align: center;
}
.el-input {
  position: relative;
  display: inline-block;
  width: 27%;
}
/*/deep/ .el-input__inner{*/
/*  height: 50px;*/
/*}*/
/*/deep/.el-input__inner {*/
/*  !* height: 34px !important; *!*/
/*  height: 47px!important;*/
/*  line-height: 47px !important;*/
/*  font-size: 14px !important;*/
/*}*/
.el-form-item{
  margin-bottom: 25px;
}

.btn-defalut{
  color: #333;
  background-color: #eee;
  border-color: #eee;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: .1s;
  font-weight: 500;
  padding: 12px 40px;
  font-size: 14px;
  border-radius: 4px;
  margin-right: 30px;
}
.btn-defalut:hover{
  background: #f1f1f1;
  border-color: #f1f1f1;
  color: #333;
}

.success{
  color: #fff;
  background-color: #00c250;
  border-color: #00c250;
}
.success:hover {
  background: #33ce73;
  border-color: #33ce73;
  color: #fff;
}
.avatar {
width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.el-form-info{
  color: #999;
  font-size: 14px;
  line-height: 1.6;
  padding-top: 8px;
}
.gapl:hover{
  text-decoration: underline;
}
.province{
  margin-right: 10px;
}
.branchBank{
  width: 30%;
}
</style>
